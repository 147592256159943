:root {
  --bg-light-mode-color: #ffffff;
  --bg-dark-mode-color: #21313c;
  --font-dark-mode-color: #ffffff;
}
html,
body {
  height: 100%;
  font-family: "Heebo";
  transition: background-color 0.5s ease;
}

span {
  white-space: pre;
}

th {
  font-weight: bold;
  color: #89989b;
  text-align: right;
}

td {
  padding-left: 20px;
}

main {
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Navigation Bar */
#nav {
  flex-shrink: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #89989b;
}

#nav button,
#nav select {
  font-size: 1.1rem;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.theme-label {
  line-height: 35px;
  padding-left: 10px;
}

.dark-mode {
  background-color: var(
    --bg-dark-mode-color
  ); /* to match the chart's background */
  color: var(--font-dark-mode-color);
}

body span.theme-label::before {
  content: "☀️";
}

body.dark-mode span.theme-label::before {
  content: "🌙";
}

/* Body */
.body-content {
  display: flex;
}

/* Chart */
/* #chart {
  border-top: none;
  flex-grow: 1;
  height: 100%;
  width: 80%;
} */

/* Chart Info */
.chartInfo {
  font-size: 20px;
  width: 520px;
}

.chart-title {
  text-align: center;
  color: #89989b;
}

#chartDataButton {
  margin-top: 1em;
  padding: 0.5rem 1rem;
  width: 100%;
}

/* JSON Viewer */
#jsonViewer {
  visibility: hidden;
  transition: background-color 0.5s ease;
  --background-color: var(--bg-light-mode-color);
  --string-color: #00a35c;
  --preview-color: #ffc010;
  --property-color: #0498ec;
  --number-color: #b45af2;
  --boolean-color: #ef5752;
}

body.dark-mode #jsonViewer {
  --background-color: var(--bg-dark-mode-color);
  --string-color: #00ed64;
  --preview-color: #ffec9e;
}
h2{
  background-color: #FEFFED;
  padding: 30px 35px;
  margin: -10px -50px;
  text-align:center;
  border-radius: 10px 10px 0 0;
  }
  hr{
  margin: 10px -50px;
  border: 0;
  border-top: 1px solid #ccc;
  margin-bottom: 40px;
  }
  div.container{
  width: 900px;
  height: 610px;
  margin:35px auto;
  font-family: 'Raleway', sans-serif;
  }
  div.main{
  width: 300px;
  padding: 10px 50px 25px;
  border: 2px solid gray;
  border-radius: 10px;
  font-family: raleway;
  float:left;
  margin-top:50px;
  }
  input[type=text],input[type=password]{
  width: 100%;
  height: 40px;
  padding: 5px;
  margin-bottom: 25px;
  margin-top: 5px;
  border: 2px solid #ccc;
  color: #4f4f4f;
  font-size: 16px;
  border-radius: 5px;
  }
  label{
  color: #464646;
  text-shadow: 0 1px 0 #fff;
  font-size: 14px;
  font-weight: bold;
  }
  center{
  font-size:32px;
  }
  .note{
  color:red;
  }
  .valid{
  color:green;
  }
  .back{
  text-decoration: none;
  border: 1px solid rgb(0, 143, 255);
  background-color: rgb(0, 214, 255);
  padding: 3px 20px;
  border-radius: 2px;
  color: black;
  }
  input[type=button]{
  font-size: 16px;
  background: linear-gradient(#ffbc00 5%, #ffdd7f 100%);
  border: 1px solid #e5a900;
  color: #4E4D4B;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0;
  outline:none;
  }
  input[type=button]:hover{
  background: linear-gradient(#ffdd7f 5%, #ffbc00 100%);
  }
  #chartContainer {
    width: 40%
  };
  
  #chart{
    width: 40%
  };